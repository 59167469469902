
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { ActionTypes } from "@/store/action.type";
import helpers from "@/common/helpers";

import ImageCardLarge from "@/components/ImgageCardLarge.vue";

export default {
  components: {
    ImageCardLarge,
  },
  setup() {
    const store = useStore();
    const { getImgObj } = helpers();

    function fetchStories() {
      store.dispatch(ActionTypes.GET_ALBUMS);
    }

    onMounted(fetchStories);

    return {
      albums: computed(() => store.getters.albums),
      getImgObj,
    };
  },
};
