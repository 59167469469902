
import router from "@/router/index";
import { computed } from "@vue/runtime-core";

export default {
  name: "Pagination",
  props: {
    offset: { type: Number, required: true },
    pagination: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const pages = computed(() => {
      const pages: number[] = [];
      let from: number = props.pagination.current_page - Math.floor(props.offset / 2);
      if (from < 1) {
        from = 1;
      }
      let to: number = from + props.offset - 1;
      if (to > props.pagination.last_page) {
        to = props.pagination.last_page;
      }
      while (from <= to) {
        pages.push(from);
        from++;
      }
      return pages;
    });

    function isCurrentPage(page) {
      return props.pagination.current_page === page;
    }

    function changePage(page) {
      if (page > props.pagination.last_page) {
        page = props.pagination.last_page;
      }

      //change URL param "page"
      router.push({ query: { page: page } });
      emit("paginate", page);
    }

    return { pages, isCurrentPage, changePage };
  },
};
