
import { PropType } from "vue";
import { Post } from "@/common/models/post";
import helpers from "@/common/helpers";
export default {
  name: "HtmlPost",
  props: {
    post: { type: Object as PropType<Post>, required: true },
  },
  setup() {
    const formatDate = helpers();
    return formatDate;
  },
};
