
import { computed, PropType } from "vue";

import { Post } from "@/common/models/post";

import GoogleMap from "@/components/google-map/GoogleMap.vue";
import GoogleMapMarker from "@/components/google-map/GoogleMapMarker.vue";
import GoogleMapBounds from "@/components/google-map/GoogleMapBounds.vue";

export default {
  name: "MapPost",
  components: { GoogleMap, GoogleMapMarker, GoogleMapBounds },
  props: {
    post: { type: Object as PropType<Post>, required: true },
  },
  setup(props) {
    const content = computed(() => {
      return JSON.parse(props.post.content);
    });

    return { content };
  },
};
