
import { computed, onMounted, ref } from "vue";

import { ActionTypes } from "@/store/action.type";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import helpers from "@/common/helpers";

import HtmlPost from "@/components/posts/HtmlPost.vue";
import ImagePost from "@/components/posts/ImagePost.vue";
import MapPost from "@/components/posts/MapPost.vue";
import VideoPost from "@/components/posts/VideoPost.vue";
import Pagination from "@/components/Pagination.vue";
import Loader from "@/components/Loader.vue";

import StoryNotoficationSubscription from "@/components/StoryNotificationSubscription.vue";
import StoryImage from "@/components/StoryHeaderImage.vue";
export default {
  components: {
    HtmlPost,
    ImagePost,
    MapPost,
    VideoPost,
    Pagination,
    //StoryNotoficationSubscription,
    StoryImage,
    Loader,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    //show newsletter subscription modal
    const showSub = ref(false);
    const { getImgObj, getCookie, setCookie } = helpers();
    const loading = ref(true);
    function getOrder(): string {
      const cookie = getCookie(route.params.slug.toString());
      return cookie ? cookie : "asc";
    }

    function fetchStory(page?: number) {
      loading.value = true;
      let query = route.params.slug;

      const order = getOrder();

      if (order) {
        query += `/${order}`;
      }

      if (page) {
        query += `?page=${page}`;
      } else if (route.query.page != null) {
        query += `?page=${route.query.page}`;
      }

      store.dispatch(ActionTypes.GET_STORY_BY_SLUG, query);
      setTimeout(() => {
        loading.value = false;
        window.scrollTo(0, 0);
      }, 200);
    }
    function toggleOrder() {
      setCookie(route.params.slug.toString(), getOrder() == "asc" ? "desc" : "asc", 365);
      fetchStory();
    }

    onMounted(fetchStory);

    return {
      story: computed(() => store.getters.story),
      showSub,
      getImgObj,
      fetchStory,
      toggleOrder,
      loading,
    };
  },
};
