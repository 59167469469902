<template>
  <div class="fixed z-20 h-full w-full bg-bb-lighter flex justify-center items-center">
    <img class="w-36" src="@/assets/img/loader.gif" alt="page loader gif" />
  </div>
</template>

<script lang="ts">
export default {
  name: "Loader",
};
</script>
