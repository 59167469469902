
import { Post } from "@/common/models/post";
import { PropType } from "@vue/runtime-core";
import { Story } from "@/common/models/story";

import helpers from "@/common/helpers";
export default {
  name: "ImageCardLarge",
  props: {
    resource: {
      type: Object as PropType<Post | Story>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { getExcerpt, formatDate, getImgObj } = helpers();
    return { getExcerpt, formatDate, getImgObj };
  },
};
