
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";

import { ActionTypes } from "@/store/action.type";
import { useStore } from "vuex";

import "lightgallery.js";
import "lg-fullscreen.js";
import "lg-autoplay.js";
import "lightgallery.js/src/sass/lightgallery.scss";

import helpers from "@/common/helpers";
declare const window: any;
export default {
  name: "Album",

  setup() {
    const store = useStore();
    const route = useRoute();

    const { getImgObj, getImgPath, formatDate } = helpers();

    function initGallery(): any {
      window.lightGallery(document.getElementById("lightgallery"), {
        thumbnail: true,
        download: false,
        selector: "a",
      });
    }

    function fetchAlbum() {
      store.dispatch(ActionTypes.GET_ALBUM_BY_SLUG, route.params.slug).then(
        window.setTimeout(() => {
          initGallery();
        }, 300)
      );
    }

    onMounted(() => {
      fetchAlbum();
    });

    return {
      album: computed(() => store.getters.album),
      getImgPath,
      getImgObj,
      formatDate,
    };
  },
};
